const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.debmarine.forwoodsafety.com',
    APP_CLIENT_ID: '',
    APIGATEWAY: 'https://0szmyay8ie.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://ceu237g61k.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'resources-container-1.3.0',
    HOSTNAME: 'https://resources.debmarine.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.debmarine.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.debmarine.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.debmarine.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.debmarine.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
